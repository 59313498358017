import React from "react"

import { Box } from "@chakra-ui/layout"
import { Flex, Heading, Stack, Text } from "@chakra-ui/react"

import { Image } from "../Image"
import { PageSection } from "../page-section"

export const TheMenu = (): JSX.Element => {
  return (
    <PageSection id="eat" expandable>
      <Flex flexDirection={["column", "row"]}>
        <Box
          display={["none", "block"]}
          flex={1}
          borderRight={["none", "1px solid"]}
        >
          <Image name="windyCuddle" />
        </Box>
        <Flex
          flex={1}
          flexGrow={0}
          flexDirection="column"
          justifyContent={["center", "flex-end"]}
          textAlign="left"
          py={["72px", "auto"]}
          pr={[8, 0]}
        >
          <Stack spacing={12} px={["24px", "50px"]} mb={["auto", "72px"]}>
            <Box>
              <Heading as="h3" mb={3} fontSize="32px">
                Grazing table
              </Heading>
              <Text>
                Using some of the finest local ingredients and producers in the
                Central Goldfields Holy Goat Cheese, Long Paddock Cheese,
                Oakwood Smallgoods, Sprout Bakery, Gun Hoe Growers, Michelle’s
                Biscuits, House Pickles, Salute Oliva, Cabosse & Feve
                Chocolates, B & B Basil.
              </Text>
            </Box>
            <Box>
              <Heading as="h3" mb={3} fontSize="32px">
                Shared banquet
              </Heading>
              <Text>12hr lamb shoulder, fennel, celeriac</Text>
              <Text>Coal fired chicken, green olive tapenade</Text>
              <Text>Celeriac, cavallo nero, skordalia (V)</Text>
            </Box>
            <Box>
              <Heading as="h3" mb={3} fontSize="32px">
                Served with
              </Heading>
              <Text>
                Fromage frais, confit tomato, broad beads, fresh peas, citrus,
                sorrel (VO)
              </Text>
              <Text>Dolma zucchini flowers, beetroot, skordalia (V, GF)</Text>
              <Text>
                Cauliflower three ways, sorghum, witlof, preserved pear dressing
                (V, GF)
              </Text>
            </Box>
            <Box>
              <Heading as="h3" mb={3} fontSize="32px">
                Ice-cream social
              </Heading>
              <Stack spacing={8}>
                <Text>Your choice of flavours</Text>
                <Text listStylePos="inside">
                  Dairy
                  <Text as="ul">
                    <li>Fairy Bread (Claire&apos;s flavour concept)</li>
                    <li>Choc Brownie</li>
                    <li>Vanilla (GF)</li>
                    <li>Berry Cheesecake</li>
                    <li>Espresso (GF)</li>
                  </Text>
                </Text>

                <Text listStylePos="inside">
                  Vegan
                  <Text as="ul" listStylePos="inside">
                    <li>Lemon Lime & Bitters (V, GF)</li>
                    <li>Cookie Dough (V)</li>
                  </Text>
                </Text>
                <Box display="flex" flexDirection={["column", "row"]}>
                  <Text mr={12}>V &ndash; vegan</Text>
                  <Text mr={12}>GF &ndash; gluten freen</Text>
                  <Text>VO &ndash; vegan option</Text>
                </Box>
              </Stack>
            </Box>
            <Box>
              <Heading as="h3" mb={3} fontSize="32px">
                Local producers
              </Heading>
              <Text>
                Local producers used in our menus include Gun Hoe Growers,
                Sprout Bakery, Angel Organics, Bundarra Berkshire Pork, Oakwood
                Smallgoods, Millawa Free Range Chicken, Holy Goat Cheese, Long
                Paddock Cheese, Warialda Beef, Tuki Trout Farm, Joe Goat Farmer,
                Mt Zero Olives, Salute Oliva, B & B Basil, Good Life Farm Co.
              </Text>
            </Box>

            <Box>
              <Heading as="h3" mb={3} fontSize="32px">
                Glossary
              </Heading>
              <Text>Skordalia &ndash; Greek garlic & potato dip</Text>
              <Text>Sorghum &ndash; Cereal grain</Text>
              <Text>Witlof &ndash; Leafy vegetable, also known as endive</Text>
            </Box>
          </Stack>
        </Flex>
      </Flex>
    </PageSection>
  )
}
