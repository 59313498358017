import React from "react"

import { Box } from "@chakra-ui/layout"
import { Flex, Heading, Stack, Text } from "@chakra-ui/react"

import { Image } from "../Image"
import { PageSection } from "../page-section"

import styles from "./styles.module.css"

export const TheDrinks = (): JSX.Element => {
  return (
    <PageSection id="drink" expandable>
      <Flex flexDirection={["column-reverse", "row"]} flexShrink={0}>
        <Flex
          flex={1}
          flexGrow={0}
          flexDirection="column"
          justifyContent={["center", "flex-end"]}
          textAlign="left"
          py={["72px", "auto"]}
          pr={[4, 0]}
        >
          <Stack spacing={12} px={["24px", "50px"]} mb={["auto", "72px"]}>
            <Box>
              <Heading as="h3" mb={3} fontSize="32px">
                Sparkling
              </Heading>
              <Box marginBottom={8}>
                <Text className={styles.item}>2013 Parkside Sparkling</Text>
                <Text as="em" className={styles.em}>
                  Classic bubbles, made for celebrating weddings and giving
                  toasts.
                </Text>
              </Box>
              <Text className={styles.item}> 2022 Minim 'Blushed' Pet Nat</Text>
              <Text as="em" className={styles.em}>
                Outrageous pink natural sparkling wine. Brand new and ready to
                party.
              </Text>
            </Box>
            <Box>
              <Heading as="h3" mb={3} fontSize="32px">
                White
              </Heading>
              <Box marginBottom={8}>
                <Text className={styles.item}>
                  2021 Minim 'Faraday' Chardonnay
                </Text>
                <Text as="em" className={styles.em}>
                  Dry and textural with lemon and green apple. A crisp
                  Chardonnay that goes down a treat and often leads to good
                  times
                </Text>
              </Box>
              <Text className={styles.item}>
                2022 Minim 'Patsy' Fiano Vermintino
              </Text>
              <Text as="em" className={styles.em}>
                One of Claire’s favourites, this is a fun, savoury, crisp skin
                contact wine easily smashed when the weather warms up.
              </Text>
            </Box>
            <Box>
              <Heading as="h3" mb={3} fontSize="32px">
                Red
              </Heading>
              <Box marginBottom={8}>
                <Text className={styles.item}>
                  2022 Minim 'Hitch' Sangiovese
                </Text>
                <Text as="em" className={styles.em}>
                  We&apos;re getting hitched so thought this wine was a perfect
                  match. Vivid, fresh and aromatic, with raspberries,
                  cranberries and red jelly frogs.
                </Text>
              </Box>
              <Box marginBottom={8}>
                <Text className={styles.item}>
                  2021 Minim 'Colbinabbin' Nero d'Avola
                </Text>
                <Text as="em" className={styles.em}>
                  Dark, spiced, and a little funky. With its high acidity, this
                  wine will go really well with the lamb shoulder. When Isaac
                  tried it, his first words were “god, this wine is good”.
                </Text>
              </Box>
              <Text className={styles.item}>
                2021 Frederick Stevenson 'Pinata'
              </Text>
              <Text as="em" className={styles.em}>
                One of the first wines we had together on a picnic date in a
                park in Clifton Hill. We&apos;ve ordered a few bottles each year
                to drink on our anniversary. Seeing as Isaac&apos;s favourite
                way to drink wine is large format, this year, we had magnums
                specially made.
              </Text>
            </Box>
            <Box>
              <Heading as="h3" mb={3} fontSize="32px">
                Beer
              </Heading>
              <Text>Love Shack Lager</Text>
              <Text>Love Shack Pale Ale</Text>
            </Box>
            <Box>
              <Heading as="h3" mb={3} fontSize="32px">
                Non-alcoholic
              </Heading>
              <Text>Heaps Normal non-acoholic beer</Text>
              <Text>Lemon, lime & bitters</Text>
              <Text>Selection of soft drinks</Text>
              <Text>Soda water</Text>
            </Box>
          </Stack>
        </Flex>
        <Box
          display={["none", "block"]}
          flex={1}
          borderRight={["none", "1px solid"]}
        >
          <Image name="kisses" />
        </Box>
      </Flex>
    </PageSection>
  )
}
