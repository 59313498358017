import React from "react"
import { Box, Flex, StackProps } from "@chakra-ui/layout"

import { MenuItem } from "./menu-item"
import styles from "./styles.module.css"

export const RSVP_URL = "https://airtable.com/shrM86uFGa1IZDB29"

interface NavLinkProps {
  to: string
  label: string
}

const navLinks: Array<NavLinkProps> = [
  {
    to: "#eat",
    label: "Eat",
  },
  {
    to: "#drink",
    label: "Drink",
  },
]

const MenuItems = ({
  onClick,
  items,
  ...rest
}: { items: Array<NavLinkProps> } & StackProps): JSX.Element => {
  return (
    <Box
      as={"nav"}
      display="flex"
      width="100%"
      flexDirection="row"
      justifyContent="center"
      {...rest}
    >
      {items.map(({ label, to }, i) => {
        return (
          <MenuItem key={i} to={to} onClick={onClick}>
            {label}
          </MenuItem>
        )
      })}
    </Box>
  )
}

export const MenuNavBar = () => {
  return (
    <Box className={styles.navBar}>
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems="center" justifyContent="space-evenly" width="100%">
          <MenuItems display={"flex"} items={navLinks} />
        </Flex>
      </Flex>
    </Box>
  )
}
