import React from "react"

import { Box } from "@chakra-ui/react"

import SEO from "../components/seo"
import { TheMenu } from "../components/the-menu"
import { TheDrinks } from "../components/the-drinks"
import { MenuNavBar } from "../components/menu-nav-bar"

const MenuPage = () => (
  <Box id="top">
    <SEO />
    <MenuNavBar />
    <TheMenu />
    <TheDrinks />
  </Box>
)

export default MenuPage
